import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Chip from "../../../common/Chip";
import "./styles.css";

const BlogItem = ({
  blog: {
    description,
    title,
    createdAt,
    authorName,
    authorAvatar,
    cover,
    category,
    id,
    site,
    link,
    duration,
    authorLink
  },
  index,
}) => {
  const [fadeIn, setFadeIn] = useState(false);
  const linkto = link ? link : `/${site}/${id}`;

  const delay = `${index * 0.1}s`;

  useEffect(() => {
    setFadeIn(true);
  }, []);

  return (
    <div
      className={`blogItem-wrap ${fadeIn ? "fade-in" : ""}`}
      style={{ animationDelay: delay }}
    >
      <div className="blogItem-cover">
        <Link className="blogItem-link-top" to={linkto}>
          <img src={cover} alt="cover" className="blogItem-image" />
        </Link>
        {duration ? <div className="overlay-text">{duration}</div> : null}
      </div>

      <h3>
        <Link className="blogItem-link-top" to={linkto}>
          {title}
        </Link>
      </h3>
      <Link className="blogItem-link-top" to={linkto}>
        <p className="blogItem-desc">{description}</p>
      </Link>
      <footer>
        <Link className="blogItem-link-top" to={authorLink}>
          <div className="blogItem-author">
            <img src={authorAvatar} alt="avatar" />
            <div>
              <h6>{authorName}</h6>
              <p>{createdAt}</p>
            </div>
          </div>
        </Link>
        <Link className="blogItem-link-top" to={linkto}>
          <Chip label={category} />
        </Link>
      </footer>
    </div>
  );
};

export default BlogItem;
