export const recentPost = {
  "id": 22,
  "site": "cda",
  "title": "Week of Posibilities",
  "category": "redesign",
  "subCategory": [
    "coffee",
    "sketches",
    "potential"
  ],
  "description": "With the remake and unification of all the Silverware Games sites, it is nothing but new posibilities here. Lily made a door to the posible universe in our lead image. Michael is still a bit of a bot himself with Happy Bot. Lily tried using watercolors for the first time",
  "fullText": "<p>With the remake and unification of all the Silverware Games sites, it is nothing but new posibilities here. Lily made a door to the posible universe in our lead image. Michael is still a bit of a bot himself with Happy Bot.</p>\r\n<p>Lily tried using watercolors for the first time with Watercolor 1, and Michael had some watercolor like chalky styling for Running Red Bird even though we aren't sure how that happened!</p>",
  "authorName": "Lily and Michael",
  "authorAvatar": "/assets/images/lilymichael.jpg",
  "createdAt": "September 26, 2024",
  "cover": "/assets/images/CDA22-thumb.jpg",
  "duration": "10 pieces",
  "images": [
    "/assets/cda/24.09.27/01.L.09.27.24%20A%20World%20of%20Posibilities.jpg",
    "/assets/cda/24.09.27/02.M.09.08.24%20Happy%20Bot.jpg",
    "/assets/cda/24.09.27/03.L.09.08.24%20Just%20Wish%20For%20Love.jpg",
    "/assets/cda/24.09.27/04.M.09.22.24%20Musical%20Morning.jpg",
    "/assets/cda/24.09.27/05.L.09.16.24%20Cold%20Look.jpg",
    "/assets/cda/24.09.27/06.M.09.16.24%20Cockatoo.jpg",
    "/assets/cda/24.09.27/07.L.09.16.24%20Chromatic%20Eye.jpg",
    "/assets/cda/24.09.27/08.M.09.17.24%20Running%20Red%20Bird.jpg",
    "/assets/cda/24.09.27/09.M.09.04.24%20Sady.jpg",
    "/assets/cda/24.09.27/10.L.09.21.24%20Watercolor%201.jpg"
  ],
  "ctaButtons": [
    {
      "platformLogo": "/assets/images/cta-email.png",
      "titleText": "Email Us To Buy a Print",
      "priceTag": "$30 Per Print",
      "appIcon": "/assets/images/CDALogo.png",
      "linkTo": "mailto:coffeedoodleart@gmail.com?subject=I'd like to buy one of your prints!"
    }
  ]
};