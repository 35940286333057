import React from 'react';
import './styles.css';

const currentYear = new Date().getFullYear();

const Footer = () => (
    
    <footer className='footer'>
     <p>&copy; 2014-{currentYear} Silverware Games, Inc. All rights reserved.</p>
    </footer>
);

export default Footer;